import React from "react";
import Aos from "aos";
import store from "redux/store";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "common/i18n";
import ErrorBoundary from "utils/ErrorBoundary";
import Secondary from "./secondary";
import YuniDevTailwind from "./yunidevTailwind";

import "aos/dist/aos.css";

const App = (props) => {
  const layout = "yunidevsecondary";
  ReactGA.initialize([
    {
      trackingId: process.env.GA4_TRACKING_ID,
    },
  ]);
  ReactGA.send("pageview");

  React.useEffect(() => {
    Aos.init();
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          {layout === "yunidevtailwind" && <YuniDevTailwind {...props} />}
          {layout === "yunidevsecondary" && <Secondary {...props} />}
        </I18nextProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
