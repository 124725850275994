import React from "react";
import ProgresiveImg from "components/ProgresiveImg";
import placeHolderImg from "assets/images/no-image.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const ProgresiveImgMemoize = React.memo(ProgresiveImg);

const CardPorto = ({ title, poster, description, demoLink, sourceLink }) => {
  return (
    <div className="rounded-xl p-5 h-full relative flex flex-col shadow-sm porto-card">
      <div className="border-[1px] border-borderLight rounded-md">
        <ProgresiveImgMemoize
          lowQuality={placeHolderImg}
          hightQuality={poster}
          altText={title}
          className="rounded-md w-full object-cover max-h-52 h-full"
        />
      </div>
      <div className="flex-auto flex flex-col">
        <h3 className="w-full text-left text-xl py-2 font-semibold dark:text-white">
          {title}
        </h3>
        <div className="flex-auto text-left text-textMain font-normal text-base dark:text-teal-50">
          {description}
        </div>
        <div className="pt-4 flex justify-between">
          <a
            href={demoLink}
            className="py-1 px-6 rounded-md hover:opacity-80 transition duration-300 hover:shadow-lg btn-action"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faPlay} /> Demo
          </a>
          <a
            href={sourceLink}
            className="py-1 px-6 rounded-md hover:opacity-80 transition duration-300 hover:shadow-lg btn-action"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} /> Source
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardPorto;
