import React from "react";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Home from "views/homeSecond";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./styles/index.style.css";

const App = () => {
  const { themeMode } = useSelector((state) => state.theme);

  return (
    <div className={`app-container ${themeMode}`}>
      <div className="background-shape" />
      <div className="app-innner-container">
        <Header />
        <Home />
      </div>
      <Footer />
      <a
        id="to-top"
        href="#home"
        className="h-14 w-14 fixed z-[9999] rounded-full bottom-5 lg:bottom-20 p-4 right-4 hidden flex-col justify-center items-center hover:animate-pulse btn-arrow-up"
      >
        <FontAwesomeIcon icon={faArrowUp} className="text-white text-xl" />
      </a>
    </div>
  );
};

export default App;
