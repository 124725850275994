import React from 'react';

const ModalPopup = ({visible, title, description, onClose}) => {
    return (
        <div id="modalEl" tabIndex="-1" className={`${!visible && 'hidden' } modal fade overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[10000] md:inset-0 md:h-full bg-slate-400 bg-opacity-70 flex flex-col justify-center dark:bg-slate-700 dark:bg-opacity-60`}>
            <div className="relative p-4 w-full max-w-md h-full md:h-auto m-auto modal-dialog modal-dialog-centered transition-transform duration-300">
                <div className="animated modal-dialog relative bg-white rounded-lg shadow dark:bg-cyan-600 m-auto">
                    <div className="p-6 text-center">
                        <i className="fas fa-check-circle text-5xl text-bgDarkTwo dark:text-white"></i>
                        <h3 className="my-2 text-lg font-medium text-gray-500 dark:text-gray-300">{title}</h3>
                        <div className='font-normal text-base mb-5'>{description}</div>
                        <button 
                            onClick={onClose}
                            data-modal-toggle="popup-modal" 
                            type="button" 
                            className="text-white bg-primary hover:bg-opacity-80 focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                        >
                            Ok
                        </button>
                    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalPopup