import React from "react";
import { useEffect } from "react";

const RunningText = ({ text }) => {
  useEffect(() => {
    let i = 0;
    let j = 0;
    let isEnd = false;
    let isDeleting = false;
    let currentPhrase = [];
    const phrases = ["Developer.", "Web Designer.", "Frontend Developer."];
    const textDisplay = document.getElementById("text-running");

    const loop = () => {
      isEnd = false;
      textDisplay.innerHTML = currentPhrase.join("");
      if (i < phrases.length) {
        if (!isDeleting && j <= phrases[i].length) {
          currentPhrase.push(phrases[i][j]);
          j++;
          textDisplay.innerHTML = currentPhrase.join("");
        }

        if (isDeleting && j <= phrases[i].length) {
          currentPhrase.pop(phrases[i][j]);
          j--;
          textDisplay.innerHTML = currentPhrase.join("");
        }

        if (j === phrases[i].length) {
          isEnd = true;
          isDeleting = true;
        }

        if (isDeleting && j === 0) {
          currentPhrase = [];
          isDeleting = false;
          i++;
          if (i === phrases.length) {
            i = 0;
          }
        }
      }
      const spedUp = Math.random() * (80 - 50) + 50;
      const normalSpeed = Math.random() * (300 - 200) + 50;
      const time = isEnd ? 500 : isDeleting ? spedUp : normalSpeed;
      setTimeout(loop, time);
    };
    loop();
  }, []);
  return <span id="text-running" className="text-running" />;
};

export default RunningText;
