import React from "react";

const CardResume = ({
  year = "",
  position = "",
  institution = "",
  location = "",
}) => {
  return (
    <div className="card-resume">
      <div className="year">{year}</div>
      <div className="position">{position}</div>
      <div className="institution">{institution}</div>
      <div className="location">{location}</div>
    </div>
  );
};

export default CardResume;
