import {
  faGithub,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../styles/footer.style.css";

const Footer = () => {
  return (
    <footer className="Footer_container">
      <div className="inner-container">
        <div className="left">
          © 2022 Create with{" "}
          <FontAwesomeIcon icon={faHeart} className="text-red-400" /> by Dadang
        </div>
        <div className="right">
          <div className="social-container">
            <a
              href="https://www.linkedin.com/in/dadang-yuni-utomo-2716a3114/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className="text-xl lg:text-2xl text-textMain hover:text-primary duration-300 transition ease-in-out dark:text-white"
              />
            </a>
            <a
              href="https://github.com/dadangyuni"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon
                icon={faGithub}
                className="text-xl lg:text-2xl text-textMain hover:text-primary duration-300 transition ease-in-out dark:text-white"
              />
            </a>
            <a
              href="https://www.instagram.com/dadang_inuy/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="text-xl lg:text-2xl text-textMain hover:text-primary duration-300 transition ease-in-out dark:text-white"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCskxSa__nnGgrAYHCS_y5Pg"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon
                icon={faYoutube}
                className="text-xl lg:text-2xl text-textMain hover:text-primary duration-300 transition ease-in-out dark:text-white"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
