import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
/*eslint-disable*/
const ProgresiveImg = ({lowQuality, hightQuality, altText = 'photo', className}) => {
    const [source, setSource] = useState(lowQuality);
    const {ref: wrapperRef, inView } = useInView({threshold: 0.2});

    useEffect(()=>{
        if(inView && source === lowQuality){
            const img = new Image();
            img.src = hightQuality;
            img.onload = () => {
                setSource(hightQuality);
            };
        }
    },[inView]);
    
    return (
        <div
            ref={wrapperRef}
            style={{
                transition: 'all 0.5s',
                visibility: `${inView ? 'visible' : 'invisible'}`
            }}
        >
            <img 
                src={source} 
                alt={altText} 
                className={className}
                style={{
                    filter: `${source === lowQuality ? 'blur(25px)' : 'none'}`
                }}
            />
        </div>
    )
}



export default ProgresiveImg