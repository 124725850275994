import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const common_en = require("translations/en/common.json");
const common_id = require("translations/id/common.json");

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: "en",
  lng: localStorage.getItem("lang"),
  resources: {
    en: common_en,
    id: common_id,
  },
});

export default i18n;
