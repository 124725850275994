import React from "react";
import mypict from "assets/images/about_pict_1.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./styles/about.style.css";

const About = () => {
  return (
    <section id="about" className="about_container">
      <div className="header-container">
        <h1 className="section-title after">About</h1>
      </div>
      <div className="content-container">
        <div className="container-left">
          <div className="img-container">
            <img src={mypict} alt="dadang" />
            <div
              className="shape"
              data-image-url={mypict}
              style={{ backgroundImage: `url(${mypict})` }}
            ></div>
          </div>
        </div>
        <div className="container-right">
          <div className="intro">
            <h2 data-aos="fade-left" data-aos-duration="1000">
              I'm Dadang Yuni U.
            </h2>
            <h3 data-aos="fade-left" data-aos-duration="1500">
              A Web Developer from Indonesia.
            </h3>
            <p data-aos="fade-left" data-aos-duration="2000">
              I started my journey as a developer in 2020. I build and develop a
              web application with javascript. Most of my projects done using
              ReactJS. But, I am also improve my skill to be Fullstack
              Developer. In my spare time I like design and build my own
              application.
            </p>
          </div>
          <div className="info" data-aos="fade-up" data-aos-duration="2000">
            <h3>Personal Info</h3>
            <div className="info-contact">
              <div className="contact">
                <div className="icon">
                  <FontAwesomeIcon icon={faEnvelope} fontSize="1.5rem" />
                </div>
                <div className="content">
                  <div className="label">Email</div>
                  <div className="text">dadanginuy@gmail.com</div>
                </div>
              </div>
              <div className="contact">
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationDot} fontSize="1.5rem" />
                </div>
                <div className="content">
                  <div className="label">Location</div>
                  <div className="text">East Java, Indonesia</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
