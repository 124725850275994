import {
  faBriefcase,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CardResume from "./components/CardResume";
import "./styles/resume.style.css";

const Resume = () => {
  return (
    <section id="resume" className="resume_container">
      <div className="header-container">
        <h1 className="section-title after">Resume</h1>
      </div>
      <div className="content-container">
        <div className="resume-item">
          <div className="title">
            <div className="icon">
              <FontAwesomeIcon
                icon={faGraduationCap}
                style={{ fontSize: "1.5rem" }}
              />
            </div>
            <h1 className="label">Education</h1>
          </div>
          <div className="content-row">
            <div
              className="content-col"
              data-aos="fade-left"
              data-aos-duration="500"
            >
              <CardResume
                year="2011 - 2014"
                position="Computer & Network Engineering"
                institution="Nasional Vocational High School"
                location="East java, Indonesia"
              />
            </div>
            <div
              className="content-col"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <CardResume
                year="2014 - 2018"
                position="Computer Engineering"
                institution="National Institute of Technology"
                location="East Java, Indonesia"
              />
            </div>
          </div>
        </div>
        <div className="resume-item">
          <div className="title">
            <div className="icon">
              <FontAwesomeIcon
                icon={faBriefcase}
                style={{ fontSize: "1.5rem" }}
              />
            </div>
            <h1 className="label">Experience</h1>
          </div>
          <div className="content-row">
            <div
              className="content-col"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <CardResume
                year="2014 - 2018"
                position="Lab Assistant"
                institution="National Institute of Technology"
                location="East java, Indonesia"
              />
            </div>
            <div
              className="content-col"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <CardResume
                year="2019 - 2020"
                position="Web Programmer"
                institution="Marsudi Waluyo Hospital"
                location="East Java, Indonesia"
              />
            </div>
            <div
              className="content-col"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <CardResume
                year="2020 - present"
                position="Frontend Developer"
                institution="PT. Woolu Aksara Maya"
                location="Jakarta, Indonesia"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
