import React from "react";
import mypict from "assets/images/about_pict_1.webp";
import RunningText from "./components/RunningText";
import { t } from "i18next";

const Hero = () => {
  return (
    <section id="home" className="hero_content">
      <div className="inner-container">
        <div className="main-info">
          <div className="left" data-aos="fade-left" data-aos-duration="1000">
            <h2 className="mini-title">{t("hero.welcome")}</h2>
            <h1 className="title">
              {t("hero.title")} <span className="title-name">Dadang</span>
            </h1>
            <div className="running-title">
              <span>{t("hero.subtitle")}</span>
              <RunningText />
            </div>
            <h3 className="subtitle">{t("hero.description")}</h3>
            <div
              className="call-to-action"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <button className="btn-left">{t("hero.downloadCv")}</button>
              <a href="#about" className="btn-right">
                {t("hero.moreMe")}
              </a>
            </div>
          </div>
          <div className="right" data-aos="fade-down" data-aos-duration="2000">
            <div className="image-container">
              <img src={mypict} alt="my pict" />
              <div
                className="main"
                data-image-url={mypict}
                style={{ backgroundImage: `url(${mypict})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
