import personalSite from "assets/images/personal-site-1.webp";
import movieCatalog from "assets/images/movie-catalog-1.webp";
import personalBogger from "assets/images/personal-blogger-1.webp";
import ebookGallery from "assets/images/flipbook-reader-1.webp";

const app = {};

app.portofolioData = [
  {
    id: 1,
    poster: personalSite,
    title: "Personal Website V2",
    demoLink: "https://yunidev.netlify.app/",
    sourceLink: "https://github.com/dadangyuni/dyunidev-fe",
    description:
      "This is my personal site which contain my resume, portofolio, and latest blogpost. I built it with React and Tailwind. Using Hooks feature of ReactJS with dark mode and localization",
  },
  {
    id: 2,
    poster: movieCatalog,
    title: "Movie Catalog App",
    demoLink: "https://mcat-movie.netlify.app/",
    sourceLink: "https://github.com/dadangyuni/movie-db",
    description:
      "I built this projects using ReactJS, Axios, and Ant Design to complete my portofolio. This projects connect to TMDB API to get list of amazing movie, latest, and popular tv shows",
  },
  {
    id: 3,
    poster: personalBogger,
    title: "Personal Blogger Template",
    demoLink: "https://dadangutomodev.blogspot.com/",
    sourceLink: "https://github.com/dadangyuni/yunite-blogger-template",
    description:
      "This blogger template i built using html css which inspired from igniel.com and still under development. In this blog i used to share about technology especialy about coding, error fix and tutorial",
  },
  {
    id: 4,
    poster: ebookGallery,
    title: "Ebook Gallery App [POC]",
    demoLink: "https://pdf-flipbook-react.netlify.app/",
    sourceLink: "https://github.com/dadangyuni/pdf-reader-react",
    description:
      "This project still on POC of flipbook reader using ReactJS, react-pdf, react-pageflip. Its purpose to display ebook or pdf reading like real book",
  },
];

export default app;
