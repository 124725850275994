import React from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/contact.style.css";

const ModalPopup = React.lazy(() => import("components/ModalPopup"));

const Contact = () => {
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [modal, setModal] = React.useState({ visible: false, title: "" });
  const clearInput = (elList) => {
    Object.keys(elList).forEach((m) => {
      elList[m].value = "";
    });
  };
  const validateForm = (objValue) => {
    return new Promise((resolve, reject) => {
      let errorList = [];
      Object.keys(objValue).forEach((m) => {
        if (!objValue[m]) {
          document.getElementById(`error-${m}`).classList.remove("invisible");
          errorList.push({ name: m });
        }
      });
      if (errorList.length > 0) {
        reject({ errors: errorList });
      } else {
        resolve(objValue);
      }
    });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (value) {
      document.getElementById(`error-${id}`).classList.add("invisible");
    } else {
      document.getElementById(`error-${id}`).classList.remove("invisible");
    }
  };
  const closeModal = React.useCallback(() => {
    setModal({ visible: false, title: "" });
  }, [setModal]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoadingSend(true);
      const formElements = document.querySelector("form");
      const { name, email, subject, message } = formElements.elements;
      const payload = {
        name: name.value,
        email: email.value,
        subject: subject.value,
        message: message.value,
      };
      const validatePayload = await validateForm(payload);
      const resp = await fetch(
        `${process.env.REACT_APP_DYUNIDEV_URL}contact/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(validatePayload),
        }
      ).then((resp) => resp.json());
      clearInput(formElements.elements);
      setLoadingSend(false);
      setModal({ visible: true, title: resp });
    } catch (error) {
      setLoadingSend(false);
    }
  };
  return (
    <section id="contact" className="contact_container">
      <div className="header-container">
        <h1 className="section-title after">
          Get in <span className="title-hilight">Touch</span>
        </h1>
      </div>
      <div className="contact-wrapper">
        <div className="col-4">
          <div
            className="list-contact"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
            <span>
              <a href="mailto:dadanginuy@gmail.com">dadanginuy@gmail.com</a>
            </span>
          </div>
        </div>
        <div className="col-8">
          <form
            className="max-w-xl w-full mx-auto mb-10 form-contact"
            onSubmit={handleSubmit}
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <div className="w-full text-left">
              <label
                htmlFor="name"
                className="text-base font-normal text-textMain mb-10 dark:text-white"
              >
                Name
              </label>
              <input
                onChange={handleChange}
                id="name"
                type="text"
                className="mt-3 w-full bg-blue-100 text-dark p-3 rounded-md focus:outline-none ring-black ring-1 focus:ring-2 focus:border-primary dark:bg-stone-600 dark:ring-white  dark:text-white"
              />
              <p
                id="error-name"
                className="invisible text-red-700 font-semibold dark:text-red-400"
              >
                Please enter your name
              </p>
            </div>
            <div className="w-full flex flex-col lg:gap-8 lg:flex-row">
              <div className="w-full text-left lg:w-1/2">
                <label
                  htmlFor="email"
                  className="text-base  font-normal text-textMain mb-10 dark:text-white peer-invalid:text-red-500"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  onChange={handleChange}
                  className="peer mt-3 w-full bg-blue-100 text-dark p-3 rounded-md focus:outline-none ring-black ring-1 focus:ring-2 focus:border-primary dark:bg-stone-600 dark:ring-white  dark:text-white"
                />
                <p
                  id="error-email"
                  className="invisible text-red-700 font-semibold dark:text-red-400"
                >
                  Please enter your email
                </p>
              </div>
              <div className="w-full lg:w-1/2 text-left">
                <label
                  htmlFor="subject"
                  className="text-base  font-normal text-textMain mb-10 dark:text-white"
                >
                  Subject
                </label>
                <input
                  id="subject"
                  type="text"
                  onChange={handleChange}
                  className="peer mt-3 w-full bg-blue-100 text-dark p-3 rounded-md focus:outline-none ring-black ring-1 focus:ring-2 focus:border-primary dark:bg-stone-600 dark:ring-white  dark:text-white"
                />
                <p
                  id="error-subject"
                  className="invisible text-red-700 font-semibold dark:text-red-400"
                >
                  Please enter the subject
                </p>
              </div>
            </div>
            <div className="w-full text-left">
              <label
                htmlFor="message"
                className="text-base font-normal text-textMain mb-10 dark:text-white"
              >
                Message
              </label>
              <textarea
                id="message"
                onChange={handleChange}
                className="peer mt-3 w-full bg-blue-100 text-dark p-3 rounded-md focus:outline-none ring-black ring-1 focus:ring-2 focus:border-primary dark:bg-stone-600 dark:ring-white  dark:text-white"
              />
              <p
                id="error-message"
                className="invisible text-red-700 font-semibold dark:text-red-400"
              >
                Please enter your message
              </p>
            </div>
            <div className="w-full flex flex-wrap justify-between gap-6">
              <button
                type="submit"
                disabled={loadingSend}
                className={`hover:opacity-80 w-full hover:shadow-lg transition duration-30 btn-contact-submit ${
                  loadingSend && "cursor-not-allowed"
                }`}
              >
                {loadingSend ? (
                  <>
                    <i className="fas fa-spinner animate-spin mx-1"></i>
                    Sending...
                  </>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <React.Suspense fallback={<div />}>
        <ModalPopup
          visible={modal.visible}
          title={modal.title}
          onClose={closeModal}
        />
      </React.Suspense>
    </section>
  );
};

export default Contact;
