import React from "react";
import CardPorto from "./components/CardPorto";
import service from "./services";
import "./styles/portfolio.style.css";

const Portfolio = () => {
  return (
    <section id="portfolio" className="portfolio_container">
      <div className="header-container">
        <h1 className="section-title after">
          My <span className="title-hilight">Portfolio</span>
        </h1>
        <h3 className="section-subtitle" data-aos="slide-right">
          Here are a few projects built completely with passion and creatifity
          during my spare time
        </h3>
      </div>
      <div className="porto-content">
        <div className="flex flex-wrap items-stretch">
          {service.portofolioData.map((m) => {
            return (
              <div
                key={m.id}
                className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                style={{ flex: "0 1 auto" }}
                data-aos="slide-left"
                data-aos-duration="1000"
              >
                <CardPorto {...m} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
