import React from "react";
import About from "./About";
import Contact from "./Contact";
import Hero from "./Hero";
import Portfolio from "./Portfolio";
import Resume from "./Resume";
import "./styles/home.style.css";

const Home = () => {
  return (
    <div className="Content_container">
      <Hero />
      <About />
      <Resume />
      <Portfolio />
      <Contact />
    </div>
  );
};

export default Home;
