import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

const initialState = {
  layout: localStorage.getItem("layout") || "yunidevsecondary",
  themeMode: localStorage.getItem("themeMode") || "light",
  lang: localStorage.getItem("lang"),
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    toggleTheme: (state) => {
      if (state.themeMode === "dark") {
        localStorage.setItem("themeMode", "light");
        state.themeMode = "light";
      } else {
        localStorage.setItem("themeMode", "dark");
        state.themeMode = "dark";
      }
    },
    changeLayout: (state, action) => {
      state.layout = action.payload;
    },
    changeLang: (state, action) => {
      state.lang = action.payload;
      i18next.changeLanguage(action.payload);
      localStorage.setItem("lang", action.payload);
    },
  },
});

export const { toggleTheme, changeLayout, changeLang } = themeSlice.actions;

export default themeSlice.reducer;
