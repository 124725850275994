import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faBars,
  faBriefcase,
  faFileAlt,
  faHomeAlt,
  faMoon,
  faSun,
  faUserAlt,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import logo from "assets/images/logo.webp";
import "../styles/header.style.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme, changeLang } from "redux/features/themeSlice";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const languages = [
  { value: "en", text: "EN" },
  { value: "id", text: "ID" },
];

const Header = () => {
  const { themeMode, lang } = useSelector((state) => state.theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isShowMenu, setShowMenu] = useState(false);
  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };
  const changeLangSelect = (e) => {
    dispatch(changeLang(e.target.value));
  };
  useEffect(() => {
    const menus = document.querySelectorAll("#menu li a");
    const selectMenu = () => {
      const hash = window.location.hash;
      if (hash) {
        menus.forEach((menu) => {
          if (menu.href.indexOf(hash) > 0) {
            menu.classList.add("active");
          } else {
            menu.classList.remove("active");
          }
        });
      } else {
        const menu = [...menus].find((m) => m.href.indexOf("#home") > 0);
        menu.classList.add("active");
      }
    };
    selectMenu();
    window.addEventListener("hashchange", selectMenu, false);

    return () => window.removeEventListener("hashchange", selectMenu);
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const header = document.querySelector("header");
      const fixedNav = header.offsetTop;
      const toTop = document.querySelector("#to-top");

      if (window.pageYOffset > fixedNav) {
        toTop.classList.remove("hidden");
        toTop.classList.add("flex");
        header.classList.add("hd-blur-bg");
      } else {
        toTop.classList.add("hidden");
        toTop.classList.remove("flex");
        header.classList.remove("hd-blur-bg");
      }
    };
    window.addEventListener("scroll", onScroll);
    window.addEventListener("click", (e) => {
      const ham = document.getElementById("btn-ham");
      if (
        e.target !== ham &&
        e.target !== ham.firstChild &&
        e.target !== ham.firstChild.firstChild
      ) {
        setShowMenu(false);
      }
    });
  }, []);

  return (
    <header className="Header_container hd-fixed-top">
      <div className="header-inner">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <nav className={`nav-menu ${isShowMenu ? "" : "hide"}`}>
          <ul id="menu">
            <li>
              <a href="#home">
                <FontAwesomeIcon icon={faHomeAlt} />
                {t("header.menu.home")}
              </a>
            </li>
            <li>
              <a href="#about">
                <FontAwesomeIcon icon={faUserAlt} />
                {t("header.menu.about")}
              </a>
            </li>
            <li>
              <a href="#resume">
                <FontAwesomeIcon icon={faFileAlt} />
                {t("header.menu.resume")}
              </a>
            </li>
            <li>
              <a href="#portfolio">
                <FontAwesomeIcon icon={faBriefcase} />
                {t("header.menu.portfolio")}
              </a>
            </li>
            <li>
              <a href="#contact">
                <FontAwesomeIcon icon={faAddressBook} />
                {t("header.menu.contact")}
              </a>
            </li>
          </ul>
        </nav>
        <div className="config-action">
          <select
            onChange={changeLangSelect}
            className="select-language"
            value={lang || "en"}
          >
            {languages.map((x) => {
              return (
                <option key={x.value} value={x.value}>
                  {x.text}
                </option>
              );
            })}
          </select>
          <button className="btn-config" onClick={handleToggleTheme}>
            <FontAwesomeIcon icon={themeMode === "light" ? faMoon : faSun} />
          </button>
          <button
            id="btn-ham"
            className="btn-config active mobile-action"
            onClick={() => setShowMenu((prev) => !prev)}
          >
            <FontAwesomeIcon icon={isShowMenu ? faXmark : faBars} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
